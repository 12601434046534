.lds-ripple {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ripple div {
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite lds-ripple;
  position: absolute;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes lds-ripple {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    top: 36px;
    left: 36px;
  }

  4.9% {
    opacity: 0;
    width: 0;
    height: 0;
    top: 36px;
    left: 36px;
  }

  5% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 36px;
    left: 36px;
  }

  100% {
    opacity: 0;
    width: 72px;
    height: 72px;
    top: 0;
    left: 0;
  }
}

.node__root > circle {
  fill: #d3d3d3;
  r: 20;
}

.node__root > g > text {
  fill: "white";
}

.node__leaf > circle {
  fill: #add8e6;
  r: 20;
}

.rd3t-label > text {
  fill: #fff;
}

/*# sourceMappingURL=index.f089d163.css.map */
